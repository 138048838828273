@import '../variables';
@import '../mixins';
@import '../media';

.chatRight {
  text-align: right;
  margin-left: 20px;

  @include chat ("right");
  @include linkInTalk;
}
