@import '../variables';
@import '../mixins';
@import '../media';

.userIconInput {
  text-align: center;
  width: fit-content;

  &__preview {
    display: inline;
    position: relative;
  }
  &__photoIcon {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}