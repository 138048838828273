@import '../variables';
@import '../mixins';
@import '../media';

.footerMenuItem {
  div {
    height: 20px;
    margin-bottom: 5px;
    text-align: center;
  }

  p {
    color: $black-80;
    font-size: 9px;
  }
}
