@import '../variables';
@import '../mixins';
@import '../media';

.inputWhite {
  @include input();
  font-weight: bold;
  display: block;
  margin: 0 auto;

  &:focus {
    outline: none;
  }
}
