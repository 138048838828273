@import '../variables';
@import '../mixins';
@import '../media';

.featureItem {
  max-width: $mw;
  width: 100%;
  margin: 0 auto 40px;
  &:last-child {
    margin-bottom: 0;
  }

  .featureCaption {
    line-height: 1.5;
    font-size: 18px;
    margin-bottom: 20px;
  }

  img {
    width: 200px;
  }
}
