@import '../variables';
@import '../mixins';
@import '../media';

.inviteArea {
  @include contentArea;
  background-color: $lightgray;

  .inviteBox {
    max-width: $mw;
    width: 100%;
    margin: 0 auto 40px;

    p {
      text-align: left;
    }

    .inputWhite {
      border: 1px solid $black_20;
      border-radius: 0;
      margin: 5px 0;
    }
  }

  .whiteBtn {
    display: block;
    margin: 10px auto 0;
  }
}
