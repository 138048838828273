@import '../variables';
@import '../mixins';
@import '../media';

.inputArea {
  @include font ("sans-serif");
  height: 100%;
  background-color: $lightgray;

  form {
    height: 100%;

    .passcodeArea + button {
      color: $black;
      position: absolute;
      bottom: 40px;
      left: 50%;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      border: none;
    }
  }
}

.nicknameInput {
  margin: 20px auto 3px;
  font-size: 12px;
  + input {
    margin-bottom: 15px;
  }
}

.amplify-switchfield {
  max-width: $mw;
  width: 100%;

  .amplify-switch__wrapper {
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;

    .amplify-switch-track {
      background-color: $black_20;
    }

    .amplify-switch-track[data-checked=true] {
      background-color: $black;
    }
  }
}
