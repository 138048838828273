@import '../variables';
@import '../mixins';
@import '../media';

.snsItem {
  width: 60px;
  text-align: center;

  .snsIcon {
    padding: 16px;
    border-radius: 50%;
    background-color: #ffffff;

    img {
      width: 28px;
      height: 28px;
      vertical-align: bottom;
    }
  }

  p {
    margin-top: 4px;
    color: $black_80;
    text-align: center;
  }

  &:hover p{
    color: $blue;
  }
}
