@import '../variables';
@import '../mixins';
@import '../media';

.footerInputMessage {
  @include font ("sans-serif");
  width: 100vw;
  padding: 8px 0 calc(env(safe-area-inset-bottom) + 8px);
  background-color: #ffffff;
  text-align: left;

  .inputMessage {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    width: 100%;
    overflow: hidden;

    .previewFile {
      bottom: 0;
      display: flex;
      gap: 10px;
      width: 100%;
      overflow-x: auto;
      padding: 16px;

      &Item {
        height: 80px;
        width: 80px;
        flex-shrink: 0;
        background: #fff;

        .removeButton {
          float: right;
          position: relative;
          bottom: 10px;
          left: 10px;
          height: 25px;
          width: 25px;
          margin-bottom: -25px;
        }

        &.else {
          border: 1px solid $gray;
          padding: 5px;

          .removeButton {
            bottom: 15px;
          }

          .fileIcon {
            height: 20px;
          }

          p {
            height: 45px;
            font-size: 8px;
            overflow-wrap: break-word;
            overflow: hidden;
          }
        }

        &Image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          overflow: hidden;
          border-radius: 4px;
        }
      }
    }

    textarea {
      display: block;
      width: calc(100% - 48 * 2px);
      height: 40px;
      font-size: 16px;
      padding: 0.5em 1em;
      resize: none;
      white-space: break-spaces;
      text-rendering: auto;
      letter-spacing: normal;
      word-spacing: normal;
      line-height: 1.5;
      text-transform: none;
      text-indent: 0px;
      text-shadow: none;
      text-align: start;
      appearance: none;
      border: 0;
      background: #f8f8f8;
      border-radius: 20px;
      &:focus {
        outline: 0;
      }
    }
  }

  .actionBtn, .sendBtn {
    display: block;
    width: auto;
    border: 0;
    background: 0;
    margin: 0 3px;

    img {
      width: 40px;
      height: 40px;
      padding: 6px;
      vertical-align: bottom;
      &:hover {
        opacity: 0.6;
      }
    }
  }
  .sendBtn {
    margin: 0 auto;
  }
}
