@import '../variables';
@import '../mixins';
@import '../media';

.faqElement {
  max-width: 316px;
  margin: 0 auto 30px;
  &:last-child {
    margin-bottom: 0;
  }

  .question {
    margin-bottom: 10px;
    font-weight: bold;

    span {
      margin-right: 5px;
    }
  }
}
