@import '../variables';
@import '../mixins';
@import '../media';

.createRoomArea {
  @include contentArea;
  background-color: $lightgray;

  .createRoomMessage {
    max-width: $mw;
    margin: 0 auto 10px;
    text-align: left;
  }

  .roomBtnBox {
    max-width: $mw;
    margin: 0 auto 10px;
  }

  button {
    margin-bottom: 10px;
  }

  .inputArea {
    margin: 20px auto 10px;
  }
}
