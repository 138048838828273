@import '../variables';
@import '../mixins';
@import '../media';

.headerRoom {
  @include font ("sans-serif");
  padding: 0 10px;
  align-items: center;
  background-color: #000000;
  color: #ffffff;
  border: none;
  position: static;

  p {
    margin: 0 auto;
  }

  button {
    height: 40px;
    border: 0;
    background: none;
    padding: 6px;

    img {
      vertical-align: middle;
      width: 28px;
      height: 28px;
    }
  }
}
