@import '../variables';
@import '../mixins';
@import '../media';

.inputArea {

  .inputGray {
    @include input();
    border: 0;
    text-align: left;
    height: 32px;
    background-color: $gray;
    color: #ffffff;
    display: block;
    margin: 0 auto;

    &:focus {
      outline: none;
    }
  }
}
