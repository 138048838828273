@import '../variables';
@import '../mixins';
@import '../media';

.top {
  @include font("sans-serif");

  h2 {
    max-width: $mw;
    width: 100%;
    text-align: left;
    margin: 0 auto 40px;
    font-size: 21px;
  }

  p {
    font-size: 14px;
    max-width: $mw;
  }

  .serif {
    font-family: "Fenix";
  }
}

.mainArea {
  padding-top: 140px;
  padding-bottom: 100px;
  @include inner;
  background-color: $lightgray;
  text-align: center;

  p {
    margin: 0 auto;
    font-size: 13px;
  }

  .mainCopy {
    display: block;
    text-align: center;
    font-size: 24px;
    margin: 0 auto 14px;
    line-height: 1;
    letter-spacing: 1.2px;
  }

  .typing-text {
    display: inline-block;
    border-right: 3px solid rgba(69, 90, 100, 0.5);
    animation: typingEffect .5s step-end infinite alternate;
  }
      
  @keyframes typingEffect {
    50% {
      border-color: transparent
    }
  }

  .mainCopy + p {
    font-size: 14px;
    text-align: center;
    margin-bottom: 18px;
  }

  .inputWhite {
    /*margin-bottom: 10px;*/
  }

  .blackBtn {
    margin-top: -5px;
    margin-bottom: 15px;
  }

  .amplify-link {
    margin-top: -1em;
    color: $black;
    line-height: 2;
    display: inline-flex;
    align-items: baseline;

    .serif {
      font-size: 1.2em;
    }

    img {
      margin-left: 2px;
      align-self: center;
    }
  }
}
