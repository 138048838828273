@import '../variables';
@import '../mixins';
@import '../media';

.roomAreaWrapper {
  position: relative;
  overflow-y: auto;

  .loader {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    background: #fff;
  }
}

.roomArea {
  @include font ("sans-serif");
  position: relative;
  min-height: 100%;
  padding: 24px 10px 20px;
  background-color: $lightgray;
  
  .chatLeft, .chatRight {
    margin-bottom: 10px;
  }

  .chatLeft + .chatRight {
    margin-top: 20px;
  }

  .talkInfo {
    margin: 20px 0;
    font-size: 12px;
    color: #707C97B2;
  }
}
