@import url('https://fonts.googleapis.com/css2?family=Fenix&display=swap');

@mixin font ($type: "serif") {
  @if ($type == "serif") {
    font-family: 源ノ明朝, 游明朝, Yu Mincho, YuMincho, "Fenix", serif;
  } @else if ($type == "sans-serif") {
    font-family: Roboto, Helvetica;
  }
}

@mixin clearfix {
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}

@mixin inner {
  padding-left: 16px;
  padding-right: 16px;

  @include media ("pc") {
    padding-left: 20%;
    padding-right: 20%;
  }
}

@mixin contentArea {
  height: 100%;
  padding-top: 94px;
  padding-bottom: 60px;
  @include inner;
}

@mixin btn {
  font-size: 18px;
  max-width: $mw;
  width: 100%;
  height: $btn_h;
  border-radius: 5px;
  border: 1px solid $black;
}

@mixin blackBtn {
  @include btn;
  color: #ffffff;
  background-color: $black;
  &:hover {
  background-color: lighten($black, 10%);
  }
  &:disabled {
    background-color: lighten($black, 70%);
    border-color: lighten($black, 70%);
  }
}

@mixin borderBlackBtn {
  @include btn;
  color: $black;
  background-color: #ffffff;
  &:hover {
    background-color: darken(#ffffff, 10%);
  }
}

@mixin input ($bc: #ffffff) {
  max-width: $mw;
  width: 100%;
  height: $btn_h;
  padding: 0.5em 1em;
  border: 1px solid $bc;
  border-radius: 5px;
  text-align: center;
}

@mixin linkArrow ($clr: #ffffff) {
  position: relative;

  &::after {
   content: "";
   display: block;
   width: 5px;
   height: 5px;
   position: absolute;
   top: 50%;
   left: 10px;
   margin-top: -2.5px;
   border-top: 1px solid $clr;
   border-left: 1px solid $clr;
   transform: rotate(-45deg);
  }
}

@mixin chat ($side: "left") {
  display: flex;

  @if ($side == "left") {
    justify-content: flex-start;

    .chatIcon {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      margin-right: 8px;

      img {
        width: 100%;
        height: auto;
      }
    }
  } @else if ($side == "right") {
    justify-content: flex-end;
  }

  .chatItem {
    max-width: calc(100% - 60px);

    @if ($side == "left") {
      .chatName {
        line-height: 1;
        text-align: left;
        font-size: 11px;
        margin: 0 0 2px 0;
      }
    }
    
    .chatBox {
      font-size: 14px;
      padding: 0.5rem 0.75rem;
      line-height: 1.25;
      text-align: left;
      position: relative;
      word-break: break-word;
      word-wrap: break-word;
      white-space: pre-wrap;
      box-shadow: 1.4px 2.7px 2.7px hsl(0deg 0% 0% / 0.46);
      
      @if ($side == "left") {
        background-color: #ffffff;
        border-radius: 0 16px 16px 16px;
      } @else if ($side == "right") {
        color: #fff;
        background-color: #455A64;      
        border-radius: 16px 0 16px 16px;
      }

      p {
        margin: 0;
      }
    }

    .fileBox {
      display: flex;
      gap: 10px;
      padding: 0.5rem 0.75rem;
      position: relative;
      background-color: #ffffff;
      border-radius: 4px;
      font-size: 14px;
      line-height: 1.25;
      text-align: left;
      word-break: break-word;
      word-wrap: break-word;
      white-space: pre-wrap;

      &:hover {
        text-decoration: none;
      }

      img {
        height: 100%;
      }

      .fileText {
        color: $gray;

        .fileName {
          margin-bottom: 3px;
          font-size: 10px;
          font-weight: bold;
        }

        .fileType {
          font-size: 14px;
        }
      }
    }
  }

  .image {
    max-width: 250px;
  }

  .chatSub {
    color: $black_60;
    line-height: 1;
    font-size: 11px;
    align-self: flex-end;

      @if ($side == "left") {
        margin-left: 4px;
      } @else if ($side == "right") {
        margin-right: 4px;
      }
  }
}

@mixin chatSysMessage {
  line-height: 1;
  font-size: 12px;
  color: $black_60;
  margin: 10px 20px;
}

@mixin linkInTalk {
  a {
    color: $blue;
    text-decoration: underline;

    &:hover {
      color: $gray;
    }
  }
}