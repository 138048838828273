@import '../variables';
@import '../mixins';
@import '../media';

.firstRoomArea {
  @include contentArea;

  .inputArea {
    margin-bottom: 20px;
  }

  .blackBtn {
    margin-top: 20px;
  }
}

.roomWrapper {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100vh;
  height: 100dvh;
}