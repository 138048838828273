@import '../variables';
@import '../mixins';
@import '../media';

.snsArea {
  @include inner;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: $lightgray;

  > h3 {
    margin-bottom: 20px;
    span {
      font-size: 24px;
      font-weight: 400;
    }
  }

  .snsItems {
    max-width: $mw;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
