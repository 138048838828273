@import '../variables';
@import '../mixins';
@import '../media';

.footerLink {
  @include inner;
  height: 120px;
  padding: 10px 0 160px;
  background-color: $lightgray;

  .footerItem {
    font-size: 12px;
    margin: 4px 0;

    a {
      color: $black_80;
      margin-right: 5px;
      margin-left: 5px;
      &:hover {
        color: $blue;
      }
    }
  }

  p {
    @extend .footerItem;
    text-align: center;
  }
}
