@import '../variables';
@import '../mixins';
@import '../media';

.addToHome {
  position: fixed;
  bottom: calc(58px + env(safe-area-inset-bottom));
  left: 0;
  width: calc(100% - 32px);
  background-color: #455A64;
  padding: 10px 0 6px;
  border-radius: 50px;
  border: none;
  margin-left: 16px;
  margin-right: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);

  p {
    color: #ffffff;
    font-size: 18px;
    margin: 0 auto;
    text-align: center;
    line-height: 1em;

      span {
        font-size: 10px;
      }
  }
}

.popup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;

  .popup-content {
    position: relative;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    width: 90%;
    max-width: $mw;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);

    p {
      font-weight: bold;
      font-size: 16px;
      line-height: 1.5;
      padding-bottom: 10px;
    }

    ol {
      font-size: 14px;
      text-align: left;
    }

    button {
      display: inline-block;
      background-color: #333;
      color: #fff;
      font-size: 16px;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      margin-top: 20px;
      &:hover {
        background-color: #444;
      }
    }
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 24px;
    height: 24px;
    opacity: 0.7;
    cursor: pointer;
    
    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 2px;
      background-color: #000;
    }

    &::before {
      transform: translateY(-50%) rotate(45deg);
    }

    &::after {
      transform: translateY(-50%) rotate(-45deg);
    }
  }

}
