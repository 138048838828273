@import '../variables';
@import '../mixins';
@import '../media';

.featuresArea {
  background-color: #ffffff;
  padding-top: 50px;
  padding-bottom: 60px;
  @include inner;

  .amplify-link {
    color: $blue;
  }
}
