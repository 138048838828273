@import '../variables';
@import '../mixins';
@import '../media';

.fixedPageArea {
  @include inner;
  max-width: 860px;
  margin: 0 auto;
  padding-top: 44px;
  text-align: left;

  h1 {
    margin: 20px 0;
    text-align: left;
  }

  h2 {
    max-width: 100%;
    margin: 20px 0;
  }

  p {
    max-width: 100%;
    font-size: 14px;
    margin-bottom: 20px;
  }

  ul {
    text-align: left;
    list-style: square inside;

    li {
      list-style: inherit;
    }
  }
}
