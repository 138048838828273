@import '../variables';
@import '../mixins';
@import '../media';

.chatLeft {
  text-align: left;
  margin-right: 20px;

  @include chat ();
  @include linkInTalk;
}
