@import '../variables';
@import '../mixins';
@import '../media';

.newRoomArea {
  @include contentArea;
  background-color: $lightgray;

  label {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
