@import '../variables';
@import '../mixins';
@import '../media';

header {
  @include inner;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: #ffffff;
  width: 100%;
  height: 44px;
  padding-top: calc(env(safe-area-inset-top) + 12px);
  padding-bottom: 12px;
  border-bottom: 1px solid $black_20;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .headerLogo {
    display: inline-block;
    height: 20px;

    img {
      height: 100%;
    }
  }
  .headerMenu {
    @include font ("sans-serif");
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    vertical-align: middle;

    .amplify-link {
      color: $black_80;
      &:hover {
        color: $blue;
      }
    }
  }
}
