@import '../variables';
@import '../mixins';
@import '../media';

.inputBorderWhite {
  @include input();
  font-weight: bold;
  display: block;
  border: 1px solid #ccc;
  margin: 0 auto;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #aaa;
  }
}
