@import '../variables';
@import '../mixins';
@import '../media';

.recaptcha {
  display: inline-block;
}

.grecaptcha-badge {
  visibility: hidden;
}
