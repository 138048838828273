@import '../variables';
@import '../mixins';
@import '../media';

.chatDate {
  @include chatSysMessage;
  text-align: center;
  margin: 20px auto;

  &:first-child {
    margin-top: 0;
  }
}
