@import '../variables';
@import '../mixins';
@import '../media';

.headerRoomList {
  @include font ("sans-serif");
  @include inner;
  justify-content: center;
  background: #000000;
  color: #ffffff;
  border: none;
  z-index: 10;

  .roomListTitle {
    margin: 0 auto;
    font-size: 14px;
  }

  .addRoomButton {
    appearance: none;
    background-color: transparent;
    border: 0;
  }

  .popup {
    color: #333;
    .inputBorderWhite {
      & + .error {
        margin: 5px 0 0;
        padding: 0;
      }
    }
    
    .blackBtn:disabled {
      background-color: #e6e6e6;
      border-color: #e6e6e6;
    }
  }
}
