$mw: 316px;
$btn_h: 40px;

$white: #FFFFFF;
$blue: #007AFF;
$gray: #6C6C6C;
$mediumGray: #DDDDDD;
$lightgray: #F0F0F0;
$black: #333333;
$black_80: rgba(#333333,80%);
$black_60: rgba(#333333,60%);
$black_40: rgba(#333333,40%);
$black_20: rgba(#333333,20%);