@import '../variables';
@import '../mixins';
@import '../media';

.roomListArea {
  position: relative;
  padding-top: 44px;
  padding-bottom: 51px;
  height: 100%;
  background-color: $lightgray;

  .roomList {
    @include font ("sans-serif");
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    padding-top: 10px;
  }
}
