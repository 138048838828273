@import '../variables';
@import '../mixins';
@import '../media';

.footerMenu {
  display: flex;
  width: 100%;
  padding: 5px 0 calc(env(safe-area-inset-bottom) + 5px);
  position: fixed;
  bottom: 0;
  background: $white;
  border-top: 1px solid rgba(51, 51, 51, 0.2); 

  a {
    flex: 1;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    img {
      pointer-events: none;
    }
    
    p {
      text-align: center;
    }
  }
}
