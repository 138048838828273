@import '../variables';
@import '../mixins';
@import '../media';

.about {

  &_element {
    margin-bottom: 20px;
    text-align: left;

    .title {
      margin-bottom: 5px;
      font-size: 16px;
    }

    a {
      font-size: 14px;
    }
  }
}
