.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .spinner {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #333333;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
