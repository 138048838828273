@import 'mixins';
@import 'variables';

@font-face {
  font-family: '源ノ明朝';
  src: url('../config/font/SourceHanSerifJP-Regular.otf') format('opentype');
}

* {
  margin :0;
  padding :0;
}

body {
  @include font ("sans-serif");
  font-size: 16px;
  color: #333333;
}

button, input {
  @include font ("sans-serif");
  font-size: 16px;
}

h2 {
  font-size: 20px;
  letter-spacing: 1.1px;
}

h3 {
  font-size: 16px;
  letter-spacing: 1.1px;
}

p {
  font-size: 14px;
}

a {
  text-decoration: none;
  color: $black_60;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
  cursor:pointer;
}

ul, ol {
  padding-inline-start: 10px;
  list-style-position: inside;
}

li {
  font-size: 14px;
}

label {
  max-width: $mw;
  width: 100%;
  text-align: left;
  font-size: 14px;
  display: block;
  margin: 0 auto 10px;
}

img, video, canvas {
    overflow: hidden!important;
}
