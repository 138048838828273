@import '../variables';
@import '../mixins';
@import '../media';

.profItem {
  position: relative;
  width: 80px;

  .photoIcon {
    position: absolute;
    right: 0;
    bottom: 0;

    img {
      width: 24px;
    }
  }

  input {
    display: none;
  }
}
