@import '../variables';
@import '../mixins';
@import '../media';

.roomListItemWrapper {
  transition: all .5s;
  position: relative;
  z-index: 1;
  right: 0;
  &--showControl {
    right: 80px;

    .roomListItem {
      background-color: #e7e7e7;
    }
  }
}

.roomListItem {
  transition: all .5s;
  position: relative;
  right: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 60px;
  padding: 8px 10px;
  border-bottom: 1px solid $mediumGray;
  background-color: $lightgray;

  .name {
    margin: 0;
    color: $black;
    font-weight: bold;
  }

  .lastDate {
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 1;
  }

  .unread {
    float: right;
    min-width: 36px;
    background: #007AFF;
    border-radius: 50px;
    color: #FFFFFF;
    font-size: 10px;
    padding: 2px;
    text-align: center;
    box-shadow: 1.4px 2.7px 2.7px hsla(0, 0%, 0%, 0.46);
  }
}

.exitRoomButton {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 100%;
  bottom: 0;
  width: 80px;
  border: 0;
  padding: 0 26px;
  color: #fff;
  background-color: #FF3E3E;
  font-size: 12px;
  font-weight: bold;
}

