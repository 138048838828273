@import '../variables';
@import '../mixins';
@import '../media';

.errorArea {
  @include contentArea;
  text-align: center;

  &Text {
    margin-bottom: 50px;
  }

  .blueBtn {
    margin-top: 40px;
  }
}
