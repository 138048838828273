@import '../variables';
@import '../mixins';
@import '../media';

.settingArea {
  @include font ("sans-serif");
  $bgcolor: #263238;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 0 50px;
  background-color: $bgcolor;
  color: #ffffff;
  overflow: scroll;
  z-index: 100;

  .passcodeArea {
    background-color: $bgcolor;
    color: #ffffff;
  }

  .buttonClose {
    text-align: right;

    button {
      border: 0;
      background: none;
      padding: 6px;
      height: 40px;

      img {
        width: 28px;
        height: 28px;
      }
    }
  }

  .profItem {
    height: 84px;
    margin: 40px auto;
  }

  .inputArea {
    margin: 10px;
    background-color: $bgcolor;

    label {
      margin-bottom: 3px;
      font-size: 12px;
    }
  }

  .blackBtn {
    background-color: $blue;
    border: 0;
    height: 32px;
    font-size: 16px;
  }

  .button--clicked {
    background-color: $blue;
    opacity: 0.5;
  }

  p {
    line-height: 1.5;
  }

  .progressContainer {
    position: relative;

    &__icon {
      position: absolute;
      right: calc(1em + 20px);
      bottom: 5px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: rgba(0,0,0,0.3);
    }
  }

  .nicknameInput {
    margin-top: 20px;
    + input {
      margin-bottom: 15px;
    }
  }

  .amplify-label {
    color: #fff;
  }

  .amplify-switchfield {

    .amplify-switch__wrapper {

      .amplify-switch-track {
        background-color: $gray;
      }

      .amplify-switch-track[data-checked=true] {
        background-color: $blue;
      }
    }
  }

  .amplify-link {
    max-width: $mw;
    margin: 10px auto;
    display: block;
    color: #fff;
    opacity: 0.5;
  }
  .createInvitationCodeButton {
    margin-top: 50px;
    background-color: $bgcolor;
    font-size: 18px;
  }

  .invitationCode {
    margin-top: 50px;

    + input {
      text-align: center;
    }
  }

  .passcodeArea + button {
    color: #fff;
    background: transparent;
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    border: none;
  }
}
