@import '../variables';
@import '../mixins';
@import '../media';

.faqArea {
  background-color: #ffffff;
  padding-top: 40px;
  padding-bottom: 60px;
  @include inner;

  .amplify-link {
    color: $blue;
  }

  p {
    text-align: left;
    margin: 0 auto;
  }
}
