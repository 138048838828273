@import '../variables';
@import '../mixins';
@import '../media';

.passcodeArea {
  @include font ("sans-serif");
  padding: 120px 16px 0;
  color: $black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: $lightgray;

  .passcodeWrap {
    height: 100px;
    max-width: $mw;
    padding: 0 30px;

    label {
      text-align: center;
    }

    .passcode {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 10px;

      &Child {
        flex: 1;
        text-align: center;
        margin: 0 4px;
        width: 100%;
        height: 50px;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
